export const usePropertyParsing = (properties) => {
  console.log(properties)
  if (properties.vorschau_bilder) {
    properties.vorschau_bilder = JSON.parse(properties.vorschau_bilder)
  }
  if (properties.questions) {
    properties.questions = JSON.parse(properties.questions)
  }
  if (properties.ansprechpartner) {
    properties.ansprechpartner = JSON.parse(properties.ansprechpartner)
  }
  if (properties.link) {
    properties.link = JSON.parse(properties.link)
  }
  if (properties.video) {
    const regExp =
      /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
    properties.video = properties.video.match(regExp)[1]
  }
  if (properties.logos_partner) {
    properties.logos_partner = JSON.parse(properties.logos_partner)
  }
  if (properties.overlay_text_bilder) {
    properties.overlay_text_bilder = JSON.parse(properties.overlay_text_bilder)
  }
  if (properties.overlay_bilder) {
    properties.overlay_bilder = JSON.parse(properties.overlay_bilder)
  }
  if (properties.qa_fragen_antworten) {
    properties.qa_fragen_antworten = JSON.parse(properties.qa_fragen_antworten)
  }
  console.log(properties)
  return properties
}
