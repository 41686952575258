<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" height="14" width="14">
        <g>
            <line x1="13.5" y1="7" x2="0.5" y2="7" fill="none" stroke="#000000" stroke-linecap="round"
                stroke-linejoin="round"></line>
            <polyline points="4 3.5 0.5 7 4 10.5" fill="none" stroke="#000000" stroke-linecap="round"
                stroke-linejoin="round"></polyline>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'ArrowLeftIcon'
}
</script>
