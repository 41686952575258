<template>
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_111_488)">
            <path
                d="M6 0.5C4.93913 0.5 3.92172 0.921427 3.17157 1.67157C2.42143 2.42172 2 3.43913 2 4.5C2 6.255 4.5 10.5 5.575 12.26C5.61984 12.3324 5.68241 12.3921 5.75679 12.4335C5.83116 12.4749 5.91488 12.4966 6 12.4966C6.08512 12.4966 6.16884 12.4749 6.24321 12.4335C6.31759 12.3921 6.38016 12.3324 6.425 12.26C7.5 10.5 10 6.255 10 4.5C10 3.43913 9.57857 2.42172 8.82843 1.67157C8.07828 0.921427 7.06087 0.5 6 0.5V0.5ZM6 6.25C5.65388 6.25 5.31554 6.14736 5.02775 5.95507C4.73997 5.76278 4.51566 5.48947 4.38321 5.1697C4.25076 4.84993 4.2161 4.49806 4.28363 4.15859C4.35115 3.81913 4.51782 3.50731 4.76256 3.26256C5.00731 3.01782 5.31912 2.85115 5.65859 2.78363C5.99806 2.7161 6.34993 2.75076 6.6697 2.88321C6.98947 3.01566 7.26278 3.23997 7.45507 3.52775C7.64736 3.81554 7.75 4.15388 7.75 4.5C7.75 4.96413 7.56563 5.40925 7.23744 5.73744C6.90925 6.06563 6.46413 6.25 6 6.25Z"
                fill="#1A677F" />
        </g>
        <defs>
            <clipPath id="clip0_111_488">
                <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'PinPopupIcon'
}
</script>
