export const useCreateFeatureCollection = (informations) => {
  const geoJSON = { type: 'FeatureCollection', features: [] }
  informations.forEach((element) => {
    const coordinates = [element['ort_koordinaten'].longitude, element['ort_koordinaten'].latitude]
    const feature = { type: 'Feature' }
    feature['properties'] = element
    feature['geometry'] = { type: 'Point', coordinates: coordinates }
    geoJSON.features.push(feature)
  })
  return geoJSON
}
