<template>
    <button class="" @click="$emit('close')">
        <CloseOverlayIcon />
    </button>
</template>

<script>
import CloseOverlayIcon from './Icons/CloseOverlayIcon.vue';


export default {
    name: "CloseButton",
    components: { CloseOverlayIcon }
}
</script>
