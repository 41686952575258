<template>
    <footer className="select-none py-0 bg-grey-background-footer lg:mb-0 xxl:px-0">
        <div class="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
            <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                <div class="px-5 py-2">
                    <a href="https://talenttransfer.de/impressum/" class="text-base text-gray-500 hover:text-gray-900"
                        target="_blank">Impressum</a>
                </div>
                <div class="px-5 py-2">
                    <a href="https://talenttransfer.de/datenschutzerklaerung/"
                        class="text-base text-gray-500 hover:text-gray-900" target="_blank">Datenschutzerklärung</a>
                </div>

            </nav>

            <p class="mt-8 text-center text-base text-gray-400">&copy; 2022 Intap network GmbH All rights reserved.</p>
        </div>
    </footer>
</template>

<script>


export default {
    name: "FooterComponent",
}
</script>
