<template>
    <div class="space-y-8">
        <img v-if="properties.titelbild_overlay" :src="properties.titelbild_overlay"
            class="rounded-md shadow-md max-h-72 w-full object-contain" />
        <div id="contact-person-box" class="bg-gray-100 p-8 rounded-md flex  mb-14">
            <img v-if="properties.ansprechpartner.bild" :src="properties.ansprechpartner.bild"
                class="rounded-full object-cover w-48 h-48 mr-8" />
            <div class="justify-between flex flex-col">
                <h2 v-if="properties.contactPersonHeadline" class="text-headlines text-2xl mb-2">{{
                        properties.contactPersonHeadline
                }}</h2>
                <p v-if="properties.ansprechpartner.beschreibung" class="text-cyan-900 text-lg">{{
                        properties.ansprechpartner.beschreibung
                }}</p>
                <div class="justify-self-end">
                    <p v-if="properties.ansprechpartner.name" class="font-bold text-cyan-900">{{
                            properties.ansprechpartner.name
                    }}</p>
                    <p v-if="properties.ansprechpartner.position" class="text-sm text-cyan-900"> {{
                            properties.ansprechpartner.position
                    }}</p>
                </div>
            </div>
        </div>
        <div class="space-y-14">
            <h2 v-if="properties.ansprechpartner.fragen_antworten_ueberschrift"
                class="text-headlines font-bold text-2xl text-center">
                {{ properties.ansprechpartner.fragen_antworten_ueberschrift }}
            </h2>
            <div v-if="properties.ansprechpartner.fragen_antworten" class="grid grid-cols-3 gap-8">
                <div class="space-y-4" v-for="(question, index) in properties.ansprechpartner.fragen_antworten"
                    :key="index">
                    <p class="text-center font-semibold">{{ question.frage }}</p>
                    <p class="text-cyan-900">
                        {{ question.antwort }}
                    </p>
                </div>
            </div>
        </div>
        <div class="w-full flex justify-center" v-if="properties.link">
            <a class="border border-solid border-black rounded-sm p-2 hover:bg-cyan-900 hover:text-white hover:shadow-md hover:transition-all hover:duration-500"
                :href="properties.link.url" target="_blank">{{ properties.link.title }}</a>
        </div>
        <div class="flex justify-center">
            <ThreeImageComponent v-if="properties.overlay_bilder" :vorschau_bilder="properties.overlay_bilder"
                class="max-w-screen-xl gap-20" />
        </div>
    </div>
</template>

<script>
import { anyTypeAnnotation } from '@babel/types';
import ThreeImageComponent from '../ThreeImageComponent.vue';

export default {
    name: 'InformationOverlay',
    components: {
        ThreeImageComponent
    },
    props: {
        properties: {
            company: String,
            place: String,
            popupText: String,
            featuredImage: String,
            headerImage: String,
            contactPersonImage: String,
            contactPersonHeadline: String,
            contactPersonDescription: String,
            contactPersonName: String,
            contactPersonPosition: String,
            questionSectionHeadline: String,
            questions: anyTypeAnnotation,
            link: String,
            linkText: String,
            overlay_bilder: Array,
        }
    }
}
</script>
