import { type } from "os";
<script>

import mapboxgl from "mapbox-gl";
import { nextTick, createApp } from 'vue'
import AnimatedPopup from 'mapbox-gl-animated-popup'
import "mapbox-gl/dist/mapbox-gl.css";
import { onMounted, ref } from "vue";
import Companies from './assets/companies.json'
import Events from './assets/events.json'
import Pin from './assets/icons/pin.png';
import CompaniesImage from './assets/icons/companies.png';
import InformationOverlay from "./components/InformationOverlay/InformationOverlay.vue";
import { useFetch } from './composables/useFetch.js'
import { useCreateFeatureCollection } from './composables/useCreateFeatureCollection.js'
import { useMapbox } from "./composables/useMapbox.js"
import { usePropertyParsing } from './composables/usePropertyParsing.js'
import MapPopup from './components/MapPopup.vue'
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'

export default {
  name: "App",
  // eslint-disable-next-line
  setup() {
    const types = ref([
      { name: "all", visible: true, label: "alle Anzeigen", excludeFromMapbox: true, showAllLayers: true },
      { name: "events", visible: false, label: "Events", dataSet: undefined, image: Pin },
      { name: "companies", visible: false, label: "Companies", dataSet: undefined, image: CompaniesImage },
    ]);
    const currentType = ref(0);
    const overlayOpen = ref(false);
    const overlayType = ref("");
    const map = ref();
    const popupProperties = ref();
    const currentPopup = ref();

    const isLoading = ref(true)

    const mode = ref('api')

    mapboxgl.accessToken = "pk.eyJ1IjoicGhtdSIsImEiOiJjaWw2bTdlZmcwNGFtdTZsdnhkZnVlMm5hIn0.qGjPQ4_Xv_QXRgN_sL5Gsw";

    function setLayerVisibility(clickedLayer) {
      currentType.value = clickedLayer;
      const showAllLayers = types.value[currentType.value].showAllLayers;
      types.value.forEach((element, index) => {
        if (element.excludeFromMapbox) {
          return;
        }
        const newVisibility = showAllLayers ? "visible" : clickedLayer === index ? "visible" : "none"
        map.value.setLayoutProperty(element.name, "visibility", newVisibility);
        map.value.setLayoutProperty(`clusters_${element.name}`, "visibility", newVisibility);
        map.value.setLayoutProperty(`cluster-count_${element.name}`, "visibility", newVisibility);
      });
    }

    function openOverlay(type) {
      overlayOpen.value = true;
      overlayType.value = type;
    }

    function closePopup() {
      currentPopup.value.remove()
      currentPopup.value = undefined
    }

    function closeOverlay() {
      overlayOpen.value = false
      closePopup()
    }

    onMounted(async () => {
      const { data: dataEvents, hasError: hasErrorEvents } = await useFetch('https://talenttransfer.de/wp-json/wp/v2/map-events?_fields=acf&acf_format=standard')
      // const { data: dataEvents, hasError: hasErrorEvents, isLoading: isLoadingEvents } = await useFetch('http://localhost:10023//wp-json/wp/v2/map-events?_fields=acf&acf_format=standard')
      if (!hasErrorEvents.value) {
        if (!mode.value || mode.value === 'demo') {
          types.value.filter((value) => value.name === 'events')[0].dataSet = Events
        } else {
          types.value.filter((value) => value.name === 'events')[0].dataSet = useCreateFeatureCollection(dataEvents.value)
        }
      }


      const { data: dataCompanies, hasError: hasErrorCompanies, isLoading: isLoadingCompanies } = await useFetch('https://talenttransfer.de/wp-json/wp/v2/map-companies?_fields=acf&acf_format=standard')
      // const { data: dataCompanies, hasError: hasErrorCompanies, isLoading: isLoadingCompanies } = await useFetch('http://localhost:10023//wp-json/wp/v2/map-companies?_fields=acf&acf_format=standard')
      isLoading.value = isLoadingCompanies.value
      if (!hasErrorCompanies.value) {
        if (!mode.value || mode.value === 'demo') {
          types.value.filter((value) => value.name === 'companies')[0].dataSet = Companies
        }
        else {
          types.value.filter((value) => value.name === 'companies')[0].dataSet = useCreateFeatureCollection(dataCompanies.value)
        }
      }

      const returnedMapboxObject = useMapbox(map.value, types.value, popupProperties.value, currentPopup.value)
      map.value = returnedMapboxObject.map
      types.value = returnedMapboxObject.types
      popupProperties.value = returnedMapboxObject.popupProperties
      currentPopup.value = returnedMapboxObject.currentPopup

      types.value.forEach((element) => {
        if (element.excludeFromMapbox) {
          return
        }
        map.value.on('click', element.name, (e) => {
          if (!e.originalEvent.defaultPrevented) {
            e.originalEvent.preventDefault();

            if (currentPopup.value) {
              closePopup()
              return
            }

            const type = element.name
            let properties = e.features[0].properties
            popupProperties.value = usePropertyParsing(properties)

            currentPopup.value = new AnimatedPopup({
              anchor: 'center',
              closeOnClick: false,
              closeButton: false,
              openingAnimation: {
                duration: 800,
                easing: 'easeOutBack',
                transform: 'scale',
              },
              closingAnimation: {
                duration: 300,
                easing: 'easeInBack',
                transform: 'scale',
              },
            })
              .setLngLat(map.value.getCenter())
              .setHTML('<div id="popup-content"></div>')
              .addTo(map.value)
            nextTick(() => {
              createApp(MapPopup, {
                type: type,
                properties: properties,
                openOverlay: openOverlay,
                closePopup: closePopup,
              }).mount('#popup-content')
              currentPopup.value._update()
            })
          }
        })


        map.value.on('click', 'saxony', (e) => {
          if (currentPopup.value || map.value.queryRenderedFeatures(e.point)[0].layer.id !== "saxony") {
            return
          }
          const city = e.features[0]['properties']['GEN']
          if (
            e.features[0]['properties']['BEZ'] === 'Kreisfreie Stadt' &&
            (city === 'Chemnitz' || city === 'Leipzig' || city === 'Dresden')
          ) {
            if (city === 'Chemnitz') {
              map.value.flyTo({ center: [12.9214, 50.8278], zoom: 11 })
            } else if (city === 'Leipzig') {
              map.value.flyTo({ center: [12.3731, 51.3397], zoom: 11 })
            } else if (city === 'Dresden') {
              map.value.flyTo({ center: [13.7373, 51.0504], zoom: 11 })
            }
          }

        })
      })
    });
    return { types, currentType, setLayerVisibility, overlayOpen, overlayType, popupProperties, closePopup, closeOverlay, isLoading };
  },
  components: { InformationOverlay, Footer, Header }
}
</script>

<template >
  <div class="flex flex-col overflow-hidden min-h-screen">
    <Header />
    <div class="flex-1 w-full  overflow-hidden flex items-center justify-center text-left">
      <div v-if="isLoading" class="z-20 absolute top-0 flex justify-center w-full bg-white h-full items-center">
        <p class=" text-brand animate-pulse text-2xl">Map is
          loading</p>
      </div>
      <div class="self-stretch flex-1 flex items-center flex-col ">
        <div class="my-2 flex">
          <button v-for="(p, index) in types" :key="index"
            :class="['px-4 py-2 leading-none text-black border-solid border-black border-y flex items-center gap-2', (currentType === index ? 'bg-brand text-white' : ''), (index === 0 ? 'rounded-l-lg border-x' : index === types.length - 1 ? 'rounded-r-lg border-x' : '')]"
            @click="setLayerVisibility(index)">
            <img :src="p.image" />
            {{
            p.label
            }}
          </button>
        </div>
        <div id="map" class="h-full w-full" />

        <transition :duration=" { enter: 800, leave: 200 }" enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut">
          <div v-if="overlayOpen" class="boundary  absolute top-0 w-full">
            <InformationOverlay :type="overlayType" :properties="popupProperties"
              @closeInformationOverlay="() => closeOverlay()" />
          </div>
        </transition>
      </div>
    </div>
    <Footer ref="footer" />
  </div>
</template>

<style>
@import url('https: //use.typekit.net/rbd3sma.css');

.boundary {
  /* max-width: 1242px; */
  height: 1200px;
}
</style>
