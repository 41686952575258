<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_15_147)">
            <path
                d="M12 23.249C18.2132 23.249 23.25 18.2122 23.25 11.999C23.25 5.78582 18.2132 0.749023 12 0.749023C5.7868 0.749023 0.75 5.78582 0.75 11.999C0.75 18.2122 5.7868 23.249 12 23.249Z"
                stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.5 16.499L16.5 7.49902" stroke="black" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M16.5 16.499L7.5 7.49902" stroke="black" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_15_147">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'CloseOverlayIcon'
}
</script>
