<template>
    <div class="grid grid-cols-3 gap-2">
        <img v-for="(item, index) in vorschau_bilder" :key="index" :src="item.bild"
            class="aspect-square object-cover border border-solid border-gray-200 shadow" />
    </div>
</template>

<script>

export default {
    name: "ThreeImageComponent",
    props: {
        vorschau_bilder: Array
    }
}
</script>
