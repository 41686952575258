<template>
    <header :ref="refProp">
        <nav class="mx-auto max-w-7xl" aria-label="Top">
            <div class="flex w-full items-center justify-between border-b border-indigo-500 py-6 lg:border-none">
                <div class="flex items-center gap-12">
                    <a class="flex items-center gap-1  text-gray-600 hover:text-gray-900"
                        href="javascript:history.back()">
                        <ArrowLeftIcon class="text-lg" />
                        zurück
                    </a>
                    <a href="https://talenttransfer.de">
                        <span class="sr-only">talenttransfer</span>
                        <img class="h-10 w-auto" src="@/assets/icons/logo.jpeg" alt="">
                    </a>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import ArrowLeftIcon from './Icons/ArrowLeftIcon.vue';


export default {
    name: "HeaderComponent",
    components: { ArrowLeftIcon },
    props: {
        refProp: String
    }
}
</script>
