import { reactive, toRefs, ref } from 'vue'

const isLoading = ref(false)

export const useFetch = async (url, options) => {
  const state = reactive({
    data: null,
    errorMessage: 'null',
    
    hasError: false,
  })
  const fetchData = async () => {
    isLoading.value = true
    try {
      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      state.data = await response.json()
      state.data = state.data.map((item) => item.acf)
    } catch (error) {
      const typedError = error
      state.hasError = true
      state.errorMessage = typedError.message
    } finally {
      isLoading.value = false
    }
  }
  await fetchData()
  return { ...toRefs(state), isLoading }
}
