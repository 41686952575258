import mapboxgl from 'mapbox-gl'

export const useMapbox = (map, types, popupProperties, currentPopup) => {
  map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/phmu/cl6gfk848000m15uhbvw8piax',
    minZoom: 6,
    zoom: 6,
    maxZoom: 12,
    center: [13.204185, 50.891055],
    attributionControl: false,
  })

  map.on('load', () => {
    const bnds = map.getBounds()
    map.setMaxBounds(bnds)
    map.fitBounds(bnds)
    const mapHeight = document.getElementById('map').clientHeight

    if (mapHeight >= 992) {
      map.setZoom(8)
      map.setMinZoom(8)
    } else if (mapHeight >= 450) {
      map.setZoom(6.8)
      map.setMinZoom(6.8)
    } else {
      map.setZoom(5.5)
      map.setMinZoom(5.5)
    }

    types.forEach((element) => {
      if (element.excludeFromMapbox) {
        return
      }
      map.loadImage(element.image, (error, image) => {
        if (error) throw error
        map.addImage(`${element.name}_image`, image)
      })
      const datasetName = `${element.name}_dataset`

      map.addSource(datasetName, {
        type: 'geojson',
        data: element.dataSet,
        cluster: true,
        clusterMaxZoom: 9, // Max zoom to cluster points on
        clusterRadius: 50,
      })

      map.addLayer({
        id: `clusters_${element.name}`,
        type: 'circle',
        source: datasetName,
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': element.name === 'events' ? '#FEB528' : '#FFDC95',
          'circle-radius': 20,
          'circle-stroke-color': 'black',
          'circle-stroke-width': 1,
        },
      })

      map.addLayer({
        id: `cluster-count_${element.name}`,
        type: 'symbol',
        source: datasetName,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      })

      map.addLayer({
        id: element.name,
        type: 'symbol',
        source: datasetName,
        filter: ['!', ['has', 'point_count']],
        layout: {
          visibility: 'visible',
          'icon-image': `${element.name}_image`,
          'icon-size': 1,
          'icon-ignore-placement': true,
          'text-allow-overlap': true,
        },
      })

      map.on('mouseenter', element.name, () => {
        map.getCanvas().style.cursor = 'pointer'
      })

      map.on('mouseleave', element.name, () => {
        map.getCanvas().style.cursor = ''
      })
    })

    map.on('mousemove', (e) => {
      const features = map.queryRenderedFeatures(e.point)
      if (features.length > 0) {
        const city = features[0]['properties']['GEN']
        if (!city) {
          return
        }
        if (
          features[0]['properties']['BEZ'] === 'Kreisfreie Stadt' &&
          (city === 'Chemnitz' || city === 'Leipzig' || city === 'Dresden')
        ) {
          map.getCanvas().style.cursor = 'pointer'
          map.setPaintProperty('saxony', 'fill-opacity', ['match', ['get', 'GEN'], city, 1, 0.75])
        } else {
          map.getCanvas().style.cursor = ''
          map.setPaintProperty('saxony', 'fill-opacity', [
            'match',
            ['get', 'GEN'],
            city,
            0.75,
            0.75,
          ])
        }
      }
    })
  })

  map.addControl(
    new mapboxgl.NavigationControl({
      showCompass: false,
    })
  )

  return { map, types, popupProperties, currentPopup }
}
