<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.25 12.4971H0.75" stroke="#1A677F" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        <path d="M19.5 16.2471L23.25 12.4971L19.5 8.74707" stroke="#1A677F" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>

<script>
    export default {
        name: 'ArrowRightIcon'
    }
</script>
