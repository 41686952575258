<template>
    <div
        class="overlay z-10 absolute top-0 border border-solid border-black rounded-lg bg-white/75 backdrop-blur-sm pt-14 pb-6 px-14 overflow-auto">
        <div class="flex flex-col ">
            <CloseButton class="self-end -mt-12 -mr-12 " @close="$emit('closeInformationOverlay')" />
        </div>
        <EventView v-if="type === 'events'" :properties="properties" />
        <CompanyView v-if="type === 'companies'" :properties="properties" />
    </div>
</template>

<script>
import EventView from "./EventView.vue";
import CompanyView from './CompanyView.vue';
import CloseButton from '../CloseButton.vue';

export default {
    name: 'InformationOverlay',
    components: {
        EventView,
        CompanyView,
        CloseButton
    },
    props: {
        type: String,
        properties: {
            titel: String,
            name: String,
            ort_name: String,
            vorschau_text: String,
            text_partner: String,
            overlay_text: String,
            overlay_text_bilder: Array,
            overlay_bilder: Array,
            veranstaltung_bild: String,
            logo_partner: String,
            link: String,
            bewertung: String,
            titelbild_overlay: String,
            ansprechpartner: {
                name: String,
                position: String,
                bild: String,
                beschreibung: String,
                fragen_antworten_ueberschrift: String,
                fragen_antworten: Array
            },
            qa_fragen_antworten: Array
        }
    }
}
</script>
