<template>
    <div class="grid grid-cols-2 gap-20 h-full ">
        <div class="flex flex-col overflow-hidden">
            <div class="grid grid-cols-3">
                <div v-if="properties.titel" class="col-span-2">
                    <div class="text-brand font-extrabold text-lg leading-6 mb-2">{{
                            properties.titel
                    }}</div>
                    <div v-if="properties.ort_name" class="font-normal mb-4 text-neutral-600 flex gap-1 items-center">
                        <PinPopupIcon /> {{ properties.ort_name }}
                    </div>
                </div>
                <div v-if="properties.bewertung">
                    <star-rating class="flex justify-end self-center" text-class=" text-base h-4"
                        :rating="parseFloat(properties.bewertung)" :read-only="true" :increment="0.01" :max-rating="5"
                        :star-size="16" />
                </div>
            </div>
            <div v-if="properties.overlay_text" class="overflow-y-auto" v-html="properties.overlay_text" />
            <ThreeImageComponent v-if="properties.overlay_text_bilder"
                :vorschau_bilder="properties.overlay_text_bilder" />
            <div v-if="properties.qa_fragen_antworten" class="my-8 grid grid-cols-3 gap-8">
                <div class="space-y-4" v-for="(question, index) in properties.qa_fragen_antworten" :key="index">
                    <p class="text-center font-semibold">{{ question.frage }}</p>
                    <p class="text-cyan-900">
                        {{ question.antwort }}
                    </p>
                </div>
            </div>
        </div>
        <div>
            <div class="grid grid-cols-3 gap-x-6 mb-8">
                <div class="col-span-1">
                    <img v-if="properties.veranstaltung_bild" class="aspect-square w-40"
                        :src="properties.veranstaltung_bild" />
                </div>
                <div class="col-span-2 space-y-4">
                    <div v-if="properties.logos_partner"
                        :class="['grid gap-2', properties.logos_partner.length === 1 ? 'grid-cols-1' : 'grid-cols-2 ']">
                        <img class="flex justify-self-center self-center max-h-52"
                            v-for="(logo, index) in properties.logos_partner" :key="index" :src="logo.logo" />
                    </div>
                    <p v-if="properties.text_partner" v-html="properties.text_partner" />
                </div>
            </div>
            <div v-if="properties.video">
                <LiteYouTubeEmbed :id="properties.video" title="intap event video" />
            </div>
        </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import PinPopupIcon from "../Icons/PinPopupIcon.vue";
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/dist/style.css'
import ThreeImageComponent from '../ThreeImageComponent.vue'

export default {
    name: 'InformationOverlay',
    components: {
        StarRating,
        PinPopupIcon,
        LiteYouTubeEmbed,
        ThreeImageComponent
    },
    props: {
        properties: {
            titel: String,
            ort_name: String,
            bewertung: String,
            overlay_text: String,
            overlay_text_bilder: Array,
            veranstaltungs_bild: String,
            logo_partner: String,
            text_partner: String,
            video: String,
            qa_fragen_antworten: Array
        }
    }
}
</script>
