<template>
    <div class=" overflow-hidden">
        <div class="flex flex-col">
            <CloseButton class="self-end mt-2 mr-2" @close="(() => closePopup())" />
        </div>
        <div class="flex flex-col overflow-hidden space-y-2 m-4">
            <div class="grid grid-cols-3 ">
                <div v-if="properties.titel || properties.name" class="col-span-2">
                    <div class="text-brand font-extrabold text-lg leading-6 ">{{
                    type === 'companies' ? properties.name : properties.titel
                    }}</div>
                    <div v-if="properties.ort_name" class="font-normal text- text-neutral-600 flex gap-1 items-center">
                        <PinPopupIcon /> {{ properties.ort_name }}
                    </div>
                </div>

                <div class="flex justify-end self-start" v-if="properties.bewertung">
                    <star-rating :rating="parseFloat(properties.bewertung)" :read-only="true" :increment="0.01"
                        :max-rating="5" :star-size="20" :inline="true" />
                </div>
                <img v-if="properties.vorschau_bild && type === 'companies'" class="aspect-4/3 justify-self-end w-40"
                    :src="properties.vorschau_bild" />
            </div>
            <ThreeImageComponentVue v-if="properties.vorschau_bilder && type === 'events'"
                :vorschau_bilder="properties.vorschau_bilder" />
            <div v-if="properties.vorschau_text" class="font-normal text-xs text-black"> {{ properties.vorschau_text }}
            </div>

            <button class="text-brand self-end flex items-center gap-2" @click="openOverlay(type)">More
                <ArrowRightIcon />
            </button>
        </div>
    </div>
</template>

<script>
import { anyTypeAnnotation } from '@babel/types';
import StarRating from 'vue-star-rating'
import ArrowRightIcon from './Icons/ArrowRightIcon.vue'
import PinPopupIcon from './Icons/PinPopupIcon.vue'
import CloseButton from './CloseButton.vue';
import ThreeImageComponentVue from './ThreeImageComponent.vue';

export default {
    name: 'MapPopup',
    components: {
        StarRating,
        ArrowRightIcon,
        PinPopupIcon,
        CloseButton,
        ThreeImageComponentVue
    },
    props: {
        type: String,
        openOverlay: Function,
        closePopup: Function,
        properties: {
            titel: String,
            name: String,
            ort_name: String,
            vorschau_text: String,
            bewertung: String,
            vorschau_bild: anyTypeAnnotation,
            vorschau_bilder: anyTypeAnnotation
        }
    },
}
</script>

<style scoped>

</style>
